<template>
	<div class="associates-settings">
		<section class="data-table user-table" v-if="subscription_details !== null">
			<div class="filters">
				<div class="filter"></div>
				<div class="sort-search">
					<button
						class="button"
						@click="show_new_assoc_modal = true"
						:disabled="
							subscription_details.current_users >= subscription_details.users
						"
					>
						{{ this.mode === 'labs' ? 'Neues Labor' : 'Neue Praxis' }}
						hinzufügen
					</button>
				</div>
			</div>
			<table class="table">
				<thead>
					<th>Name</th>
					<th>Kontakt-E-Mail Adresse</th>
					<th>Aktionen</th>
				</thead>
				<tbody>
					<tr v-for="(assoc, idx) in associates" :key="assoc.UUID">
						<td>{{ assoc.name }}</td>
						<td>{{ assoc.contact_email }}</td>
						<td>
							<button
								class="option"
								v-if="assoc.account_id === null"
								@click.prevent="deleteAssoc(idx, assoc.UUID)"
							>
								<unicon
									name="times"
									:fill="'#006a9f'"
									icon-style="line"
									width="21"
									height="21"
								/>
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</section>
		<new-assoc-modal
			v-if="show_new_assoc_modal"
			:mode="mode"
			@close="reload"
			@reload="reload"
		>
		</new-assoc-modal>
	</div>
</template>

<script>
import Swal from 'sweetalert2';
export default {
	props: ['mode'],
	components: {
		newAssocModal: () =>
			import('@/components/layout/modals/new-assoc-modal.vue')
	},
	data() {
		return {
			associates: [],
			show_new_assoc_modal: false,
			subscription_details: null
		};
	},
	mounted() {
		this.getAssociates();
		this.get_subscription_details();
	},
	methods: {
		async getAssociates() {
			let url = this.mode === 'labs' ? '/doctor/get_labs' : '/lab/get_doctors';
			const response = await this.$api.post(
				url,
				{},
				{
					headers: {
						Authorization: `Bearer ${this.$store.getters.getUserToken}`
					}
				}
			);
			if (response.status === 200) {
				this.associates = response.data;
			}
		},
		async get_subscription_details() {
			const subscription = await this.$api.post(
				'/account/get_subscription_details',
				{
					account_id: this.$store.getters.getAccount.account_id
				},
				{
					headers: {
						Authorization: `Bearer ${this.$store.getters.getUserToken}`
					}
				}
			);
			this.subscription_details = subscription.data;
		},
		async deleteAssoc(idx, UUID) {
			const result = await Swal.fire({
				title: 'Sind Sie sicher? ',
				text: `Soll ${this.mode === 'labs' ? 'das Labor' : 'die Praxis'} ${
					this.associates[idx].name
				} wirklich gelöscht werden?`,
				icon: 'warning',
				showCloseButton: false,
				showCancelButton: true,
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
				confirmButtonText: 'Ja!',
				cancelButtonText: 'Abbrechen'
			});
			if (result.isConfirmed) {
				let url =
					this.mode === 'labs' ? '/doctor/delete_lab' : '/lab/delete_doctor';
				const deleteReposnse = await this.$api.post(
					url,
					{ UUID: UUID },
					{
						headers: {
							Authorization: `Bearer ${this.$store.getters.getUserToken}`
						}
					}
				);
				if (deleteReposnse.status === 200) {
					this.reload();
				}
			}
		},
		reload() {
			this.show_new_assoc_modal = false;
			this.getAssociates();
			this.get_subscription_details();
		}
	}
};
</script>

<style lang="scss">
.associates-settings {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr auto;
	box-shadow: 0 0 20px -12px #394150;
	padding: 3rem;
	background-color: $background-color;
	justify-content: center;
	align-items: flex-start;
	justify-items: center;
}
</style>
